<template>
  <div class="bbr-promo--details">
    <v-row>
      <v-col lg="6" md="12">
        <div class="d-flex justify-space-between align-center mb-4">
          <v-skeleton-loader
            v-if="isLoading"
            class="mr-auto"
            type="heading"
            width="400"
          />
          <h2 v-else class="mr-auto">Promo Details</h2>

          <v-btn
            v-if="!isLoading"
            class="primary--text back-button mr-5 px-6"
            :loading="form.$busy"
            @click="$router.go(-1)"
            text
          >
            <v-icon> {{ icons.back }} </v-icon>
            Go Back
          </v-btn>

          <v-btn
            v-if="!isLoading"
            class="bg-primary-gradient primary next-button px-12"
            :loading="form.$busy"
            @click="savePromoDetails"
            depressed
            text
          >
            <span> Save Promo </span>
            <v-icon> {{ icons.check }} </v-icon>
          </v-btn>

          <v-skeleton-loader v-if="isLoading" class="mr-5" type="button" />
          <v-skeleton-loader v-if="isLoading" type="button" />
        </div>

        <div class="bbr-trainer--details-form-container">
          <promo-details-form
            :data="form"
            :saving="form.$busy"
            :deleting="isDeleting"
          />
        </div>
      </v-col>
    </v-row>

    <confirm-dialog ref="saveChanges" />
  </div>
</template>

<script>
import PromoDetailsForm from '../components/PromoDetailsForm'
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import Form from '@/utils/form'

import { mapActions, mapGetters, mapMutations } from 'vuex'
import { mdiArrowLeft, mdiCheck } from '@mdi/js'
import { get, isEmpty } from 'lodash'

export default {
  name: 'PromoDetails',

  components: {
    PromoDetailsForm,
    ConfirmDialog,
  },

  data() {
    return {
      form: new Form({
        id: null,
        title: null,
        image: null,
        is_featured: null,
        description: null,
        http_method: 'post',
        promo_type_id: null,
        url: null,
      }),
      icons: {
        check: mdiCheck,
        back: mdiArrowLeft,
      },
    }
  },

  computed: {
    ...mapGetters({
      isLoading: 'promos/isLoading',
      isDeleting: 'promos/isDeleting',
      selectedPromo: 'promos/selectedPromo',
    }),

    hasCurrentPromoId() {
      return !isEmpty(this.$attrs.id)
    },

    isOnUpdateMode() {
      return get(this.form, 'id', null) !== null
    },
  },

  created() {
    if (this.hasCurrentPromoId) {
      this.getPromo(this.$attrs.id)
    }
  },

  methods: {
    ...mapActions({
      getPromo: 'promos/getPromo',
      savePromo: 'promos/savePromo',
    }),

    ...mapMutations({
      insertPromo: 'promos/insertPromo',
    }),

    savePromoDetails() {
      this.form.$busy = true
      this.form.$clearErrors()

      let data = this.form.$data()
      const hasImageFile = data.image instanceof File

      if (!hasImageFile) {
        delete data.image
      }

      if (this.isOnUpdateMode) {
        data.http_method = 'put'
      }

      if (data.promo_type_id) {
        data.url = null
      } else if (data.url) {
        data.promo_type_id = null
      }

      if (!data.is_featured) {
        data.is_featured = false
      }

      this.savePromo(data)
        .then((promo) => {
          this.insertPromo(promo)
          this.form.$refresh(promo)

          this.form.$timeout(() => {
            this.form.$busy = false

            this.$router.push({ name: 'promos' })
          }, 200)
        })
        .catch(({ response }) => {
          this.form.$timeout(() => {
            if (response && response.status === 422) {
              this.form.$setErrors(response.data.errors)
            }

            this.form.$busy = false
          })
        })
    },
  },

  watch: {
    selectedPromo(promo) {
      this.form = new Form({ ...this.form.$data(), ...promo })
    },
  },

  async beforeRouteLeave(to, from, next) {
    if (this.form.$isDirty()) {
      const confirm = await this.$refs.saveChanges.open(
        'Save Changes',
        'Do you want to save changes?'
      )

      if (confirm) {
        return this.savePromoDetails()
      }
    }

    next()
  },
}
</script>

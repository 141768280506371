<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-card outlined>
        <template v-if="imageFile">
          <v-img
            v-if="defaultImage.thumb_url"
            class="primary lighten-3"
            :src="defaultImage.url"
            :lazy-src="defaultImage.thumb_url"
            aspect-ratio="2.9"
            contain
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey lighten-5" />
              </v-row>
            </template>
          </v-img>
        </template>

        <v-file-input
          v-model="uploadImage"
          class="hidden-input"
          accept=".jpg, .jpeg, .png, .svg"
          ref="file"
          @change="previewNewImageFile"
          hide-input
        />

        <v-fade-transition>
          <v-overlay v-if="hover" absolute>
            <v-btn color="primary" @click="changePromoImage" class="px-12">
              <v-icon class="mr-4"> {{ icons.image }} </v-icon>
              Change Promo Image
            </v-btn>

            <v-btn
              v-if="uploadImage"
              @click="removePromoImagePreview"
              class="ml-3 px-6"
              color="warning"
            >
              Remove
              <v-icon class="ml-2"> {{ icons.remove }} </v-icon>
            </v-btn>
          </v-overlay>
        </v-fade-transition>
      </v-card>
    </template>
  </v-hover>
</template>

<script>
import { mdiClose, mdiImage } from '@mdi/js'
import { get } from 'lodash'

export default {
  name: 'PromoImageUpload',

  props: {
    image: {
      type: [Object],
    },
  },

  data() {
    return {
      noImage: require('@/assets/images/no-image.png'),
      uploadImage: null,
      imageFile: {
        thumb_url: null,
        url: null,
      },
      icons: {
        remove: mdiClose,
        image: mdiImage,
      },
    }
  },

  created() {
    this.imageFile.url = get(this.image, 'url', null)
    this.imageFile.thumb_url = get(this.image, 'thumb_url', null)
  },

  computed: {
    defaultImage: {
      get: function() {
        return {
          url: this.imageFile.url ?? this.noImage,
          thumb_url: this.imageFile.thumb_url ?? this.noImage,
        }
      },
      set: function(newValue) {
        this.imageFile.url = newValue.url
        this.imageFile.thumb_url = newValue.thumb_url
      },
    },
  },

  methods: {
    changePromoImage() {
      this.$refs.file.$refs.input.click()
    },

    previewNewImageFile(image) {
      this.$emit('change', image)

      const file =
        image instanceof File
          ? URL.createObjectURL(new File([image], image.name))
          : null

      this.defaultImage = { url: file, thumb_url: file }
    },

    removePromoImagePreview() {
      this.uploadImage = null

      this.defaultImage = {
        url: this.image.url ?? this.noImage,
        thumb_url: this.image.thumb_url ?? this.noImage,
      }

      this.$emit('change', this.defaultImage)
    },
  },

  watch: {
    image(values) {
      this.imageFile = values
    },
  },
}
</script>

<style lang="scss">
.hidden-input {
  height: 0 !important;
  position: absolute !important;

  .v-input__prepend-outer {
    display: none;
  }
}
</style>
